import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash';

Vue.use(Vuex)

const state = {
  filters: {
    'Name': {
      asc: true
    },
    'Added': {
      asc: true
    },
    'Trial': {
      asc: true
    }
  },
  foundries: [],
  expanded: false
}

const mutations = {
  populateFoundries(state, foundries) {
    state.foundries = _.orderBy(foundries, [`fields.Name`], ['asc'])
  },
  pushFoundries(state, foundries) {
    state.foundries = _.union(state.foundries, foundries)
    state.foundries = _.orderBy(state.foundries, [`fields.Name`], ['asc'])
  },
  toggleSort(state, sort) {
    if(state.filters[sort].asc) {
      state.filters[sort].asc = false
      return state.foundries = _.orderBy(state.foundries, [`fields.${sort}`], ['desc'])
    } else {
      state.filters[sort].asc = true
      return state.foundries = _.orderBy(state.foundries, [`fields.${sort}`], ['asc'])
    }
  },
  toggleMobile(state) {
    state.expanded = !state.expanded
  },
  closeMobile(state) {
    state.expanded = false
  }
}

export default new Vuex.Store({
  state,
  mutations
})
