//
//
//
//
//
//
//
//
//
//

import HeaderFill from '../Shared/Header'

export default {
  name: 'Contact',
  components: {
    HeaderFill
  },
  data () {
    return {
    }
  },
  methods: {
    generateEmail () {
      return 'mailto:yourfriends@type.lol'
    }
  }
}
