//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Sidebar',
  data () {
    return {
      form: {
        email: null,
        foundry: null
      },
      items: [
        {
          location: '/',
          name: 'Home',
          mobile: true
        },
        {
          location: 'about',
          name: 'About'
        },
        {
          location: 'contact',
          name: 'Contact Us'
        }
      ],
      creators: [
        {
          name: 'Mark Johnson',
          url: 'https://twitter.com/markjohnsoncc'
        },
        {
          name: 'Thomas Drach',
          url: 'https://twitter.com/thomasdrach'
        }
      ]
    }
  },
  methods: {
    toggleMobile() {
      this.$store.commit('toggleMobile')
    },
    goHome() {
      this.$router.go({path: '/'})
    },
    navigate(item) {
      let loc = item.location || ''
      this.$ma.trackEvent({action: 'Navigated via Sidebar', properties: {'Location': loc}}, ['ga'])
      this.$store.commit('closeMobile')
      if (!item.external) {
        return this.$router.push({path: loc})
      }
      return this.go(loc)
    },
    go (url) {
      return window.open(url, "_self")
    },
    focusInput() {
      this.$ma.trackEvent({action: 'Clicked Email Input'}, ['ga'])
    },
    clickSubmit() {
      this.$ma.trackEvent({action: 'Clicked Submit'}, ['ga'])
    }
  }
}
