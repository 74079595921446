//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Item from '../Shared/Item.vue'
import data from '../../data/data'

export default {
  name: 'Home',
  components: {
    Item
  },
  data () {
    return {
      foundries: []
    }
  },
  methods: {
    toggleSort(sort) {
      this.$store.commit('toggleSort', sort)
      this.$ma.trackEvent({action: 'Toggled Sort', properties: {'Name': sort}}, ['ga'])
    },
    getNext(r) {
      if (!r.data.offset) return
      data.getPage(r.data.offset).then((response) => {
        this.pushArray(response)
        return this.getNext(response)
      }).catch((error) => {
  console.log(error);
})
    },
    pushArray(response) {
      this.$store.commit('pushFoundries', response.data.records)
    }
  },
  created() {
    data.get().then((response) => {
      this.$store.commit('populateFoundries', response.data.records)
      return this.getNext(response)
    }).catch((error) => {
  console.log(error);
})
  }
}
