import axios from "axios";

const airtable = "https://api.airtable.com/v0/";
//const token =
("patmvYiruwLKnXwiP.b5fceccc84ec4fb5ae9462d92e8dafe46a93cf371f8fccdcca53b5217e197704");
//const max_records = 100
const baseId = "appiJZfiuqieIDyUg";
const tableId = "Table 1";

export default {
  get() {
    return axios.get(`${airtable}${baseId}/${tableId}`, {
      headers: {
        Authorization: `Bearer patmvYiruwLKnXwiP.b5fceccc84ec4fb5ae9462d92e8dafe46a93cf371f8fccdcca53b5217e197704`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },

  getPage(offset) {
    return axios.get(`${airtable}${baseId}/${tableId}?offset=${offset}`, {
      headers: {
        Authorization: `Bearer patmvYiruwLKnXwiP.b5fceccc84ec4fb5ae9462d92e8dafe46a93cf371f8fccdcca53b5217e197704`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  },
};
