//
//
//
//
//
//
//
//
//
//


import moment from 'moment'

export default {
  name: 'Item',
  components: {},
  props: [
    'item'
  ],
  data () {
    return {
    }
  },
  computed: {
    date () {
      return moment(this.item.fields.Added).format('MM/DD/YYYY')
    },
    trial () {
      return this.item.fields.Trial || '-'
    }
  },
  methods: {
    navigate (item) {
      this.$ma.trackEvent({action: 'Selected Foundry', properties: {'Name': item.Name}}, ['ga'])
      return window.open(item.URL, "_blank")
    }
  }
}
